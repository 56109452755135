<template>
  <el-button type="primary" @click="onOpen">报备转移</el-button>

  <el-dialog title="转移" v-model="dialogVisible" width="600px" append-to-body>
    <el-form :model="form" ref="form" :rules="rules" label-width="140px">
      <el-form-item label=""> </el-form-item>
      <el-form-item label="转出人员" prop="FromConsumerId">
        <el-select
          v-model="form.FromConsumerId"
          placeholder=""
          filterable
          :filter-method="loadConsumer"
        >
          <el-option
            v-for="item in consumers"
            :key="item.id"
            :label="`${item.name}/${item.mobile}`"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="转入人员" prop="ToConsumerId">
        <el-select
          v-model="form.ToConsumerId"
          placeholder=""
          filterable
          :filter-method="loadConsumer2"
        >
          <el-option
            v-for="item in consumers2"
            :key="item.id"
            :label="`${item.name}/${item.mobile}`"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      saving: false,
      form: {},
      rules: {
        FromConsumerId: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        ToConsumerId: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      consumers: [],
      consumers2: [], //
    };
  },

  created() {},
  methods: {
    onOpen() {
      this.dialogVisible = true;
    },

    /**
     * 获取用户列表
     */
    loadConsumer(q) {
      this.$http.get("admin/v1/consumer/get_select?q=" + q).then((res) => {
        if (res.code == 0) {
          this.consumers = res.data;
        }
      });
    },

    loadConsumer2(q) {
      this.$http.get("admin/v1/consumer/get_select?q=" + q).then((res) => {
        if (res.code == 0) {
          this.consumers2 = res.data;
        }
      });
    },

    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.saving = true;
          this.$http
            .post("admin/v1/report/transfer/consumer", this.form)
            .then((res) => {
              if (res.code == 0) {
                this.$message.success(res.msg);
                this.dialogVisible = false;
              }
            })
            .finally(() => (this.saving = false));
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
